import React from 'react';
import PropTypes from 'prop-types';
import { Button, Segment } from 'semantic-ui-react';

export const GetLocation = ({onTryGetLocation}) => {
  return (
    <Segment color='orange'>
        <p>This game uses geolocation to place or search for Sparks. Your data is completely safe, and we have no way to link you to your location.</p>
        <Button className="fluid" color="orange" onClick={onTryGetLocation}>Enable Location</Button>
    </Segment>
  );
}

GetLocation.propTypes = {
  onTryGetLocation: PropTypes.func,
};
