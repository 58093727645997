import React, { PureComponent } from 'react';
import { Container, Header } from 'semantic-ui-react';

export class WhatsNew extends PureComponent {
    componentDidMount() {
  
    }
  
  
    render() {
      return (
            <Container style={{padding: "1.5rem"}}>
                <Header as='h1'>What's new</Header>
                <Header as='h2'>May 10, 2020</Header>
                <ul>
                <li>You have a chance to receive a broadcast when you use the Find sparks action! You'll see broadcasts show differently in your found sparks list.</li>
                <li>Increased the size of the Find search area.</li>
                <li>Better messaging for search results.</li>
                </ul>
            </Container>
      )
    }
  }