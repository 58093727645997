import React from 'react';
import PropTypes from 'prop-types';
import { inkButton, inkSection } from './helpers';
import { Accordion, Button, Grid, Progress } from 'semantic-ui-react';
import { palette } from '../../styles/palette';

export const FindSparks = ({
  active,
  maxSearchRadius,
  searchRadius,
  loading,
  cooldown,
  onAccordionClick,
  onFindSparks,
}) => {

    return [
      <Accordion.Title
        index={0}
        active={active}
        onClick={onAccordionClick}
        key={0}
        className={inkSection(palette.orange[25])}
        >
        <Grid columns={2}>
            <Grid.Row>
                <Grid.Column>
                    Find Sparks
                </Grid.Column>
                <Grid.Column>
                    <Progress progress="value"
                              value={searchRadius}
                              total={maxSearchRadius}
                              color='violet'
                              style={{margin: 0}}
                              active />
                </Grid.Column>
            </Grid.Row>
        </Grid>
        </Accordion.Title>,
        <Accordion.Content active={active} key={1} className={inkSection(palette.orange[25])}>
            <p style={{textAlign: "left"}}>
                You will currently find any dropped spark in a <strong>{searchRadius} foot</strong> circle around you. It increases to {maxSearchRadius} feet by 1 foot per minute.
      
            </p>
            <p style={{textAlign: "left"}}>
                You also have a chance to receive a broadcast each time you search.
            </p>
            <Button
              fluid
              onClick={onFindSparks}
              loading={loading}
              disabled={loading || cooldown > 0}
              size="big"
              className={inkButton(palette.orange[75])}>
                {cooldown <= 0 ? 'Find' : `Ready in ${cooldown} seconds`}
              </Button>
        </Accordion.Content>
  ];
}

FindSparks.propTypes = {
  active: PropTypes.bool,
  loading: PropTypes.bool,
  cooldown: PropTypes.number,
  searchRadius: PropTypes.number,
  maxSearchRadius: PropTypes.number,
  onAccordionClick: PropTypes.func,
  onFindSparks: PropTypes.func,
}
