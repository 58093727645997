import moize from 'moize/mjs';
import { css } from 'emotion';

const shouldDisableImplementation = (message, minLength = 1, maxLength = 200) =>
  message.trim().length < 1 ||
  message.trim().length > 200

export const shouldDisable = moize(shouldDisableImplementation)

export const minSparkLength = 1;
export const maxSparkLength = 200;

const setCSSProperty = (property, value) => `${property}: ${value} !important`;

export const inkSection = (bgColor) => css(`
  ${setCSSProperty('background-color', bgColor)};
  padding: 1.5rem !important;
`);

export const inkButton = (bgColor) => css(`
  ${setCSSProperty('background-color', bgColor)};
  color: white !important;
`);
