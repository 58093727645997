import React, { PureComponent } from 'react';
import { Container, Header, Button } from 'semantic-ui-react';
import {Link
} from "react-router-dom";

export class About extends PureComponent {
  componentDidMount() {

  }


  render() {
    return (
      <Container style={{ padding: "1.5rem" }}>
        <Header as='h4' color='pink'>
          <em>Note: This game is still in beta. Things may not work as perfectly and you can expect a lot of improvements.</em>
        
        </Header>
        <Header as='h1'>Little Sparkls ✨</Header>
        <p>A game about spreading encouragement 🥰and support 😇for anyone and everyone around you.
            It only takes a little spark to start a fire 🔥, and we want you to be part of the fire that warms peoples' hearts. 💖</p>
        <p>Sparks are notes of <strong>encouragement</strong>! Whether you're telling someone that they can get through it, or sharing a quote or verse that you like; as long as it's positive and encouraging, you can send it.</p>
        <Header as='h5' color='teal'>
          So go ahead, hit the Play button above and drop your first Spark!
        </Header>
          <Button as={Link} to="/whatsnew" color="violet">🆕What's New</Button>
        <h2>How it works</h2>
        <ul>
          <li>Find for a spark that has been dropped</li>
          <li>Drop a spark for someone to pick up</li>
          <li>Broadcast a spark, which can be found by anyone</li>
        </ul>
        <p><em>All Sparks are approved by our team. So it may take some time before it's available to be found.</em></p>
        <Header as='h4' color='brown'>
          Seriously, what's the point?
    </Header>
        <p>We all go through hard times. You never know when one positive thing really might make someone's day.... try it.</p>
        <Header as="h2">The details</Header>
        <p>This game uses geolocation to allow you to drop a spark or search for sparks that anyone else has dropped, which means it's <span class="teal"><strong>best experienced by playing on your mobile phone 📱</strong></span> (It also looks better on a small screen). You earn points when someone finds your spark, or when you find a spark.</p>
        <p>The goal of the game is to send and receive as much encouragement as possible. There is a scoring system!</p>
        <p>Broadcasting works slightly differently. This allows you to send a Spark out, and the system will ensure that someone finds it regardless of where they are! This means you can encourage anyone, anywhere.</p>
        <Header as='h5' color='purple'>
          Contact us, we'd love to hear your feedback.
        </Header>
        <p>
          <a href="mailto:contact@littlesparkl.com">📧email</a>
        </p>
        <p>
          Socials coming soon. <em>Just a few days we promise.</em>
        </p>
      </Container>
    )
  }
}