import React from 'react';
import PropTypes from 'prop-types';
import { Spark } from './Spark';
import { Comment, Header, Transition } from 'semantic-ui-react';

export const Sparks = ({
  foundSparks,
}) => {
  return (
  <Comment.Group>
      <Header as='h3' dividing>
          Found Sparks
      </Header>
      <Transition.Group
          as="div"
          duration={1000}
          size='huge'
          verticalAlign='middle'>
      {
          foundSparks.map((spark, index) => <Spark spark={spark} key={index}></Spark>)
      }
      </Transition.Group>
      {
        foundSparks.length == 0 ? <p><em>This is where you'll see some of your most recently found Sparks.</em></p> : null
      }
  </Comment.Group>
);
}

Sparks.propTypes = {
  foundSparks: PropTypes.arrayOf(
    PropTypes.shape({
      spark: PropTypes.shape({
        foundDate: PropTypes.string,
        date: PropTypes.string,
      }),
  })),
}
