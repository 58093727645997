import React, { PureComponent } from 'react';
import { Comment, Header } from 'semantic-ui-react';

export function Spark(props) {
    const spark = props.spark;
    const foundDate = new Date(spark.foundDate);
    const date = new Date(spark.date);
    let animationClass = 'bounce-in-fwd';
    if (Date.now() - foundDate.getTime() > 60000) {
        animationClass = 'fade-in';
    }
    return (
        <Comment className={animationClass}>
            <Comment.Content>
                <Comment.Author as='a'>{spark.isBroadcast ? <span>📡 Broadcast</span> : <span>💫Spark</span>}  from {date.toDateString()}</Comment.Author>
                <Comment.Metadata>
                    <div>found {foundDate.toDateString()} ({foundDate.toLocaleTimeString()})</div>
                </Comment.Metadata>
                <Comment.Text>
                    <Header as="h2">
                        {spark.message}
                    </Header>
                </Comment.Text>
            </Comment.Content>
        </Comment>
    )
}