import * as localForage from "localforage";
const axios = require('axios').default;
const uuidv4 = require('uuid').v4;

export class GameService {
    static instance;
    static getInstance() {
        if (this.instance) {
            return this.instance;
        }
        this.instance = new GameWebService();
        return this.instance;
    }
}

class GameWebService {
    apiEndpoint = 'https://b886eq6a4d.execute-api.us-west-1.amazonaws.com/';
    user;

    // TODO: Move these to a web request maybe?
    get constants() {
        return {
            baseSearchRadiusFeet: 4, // minimum search radius in feet
            maxSearchRadiusFeet: 40, // maximum search radius in feet
            searchRadiusIncreaseInterval: 1, // minutes
            searchRadiusIncreaseAmount: 1, // feet
            discoverRadiusMultiplier: 500
        }
    }

    constructor() {
        this.getUser()
            .then((u) => {
                this.user = u;
            });
    }

    async getUser() {
        let user;
        // this should get or create a new user
        try {
            user = await localForage.getItem('sparks_user');
            if (typeof user == 'undefined' || user === null) {
                let userId = uuidv4();
                user = this.generateNewUser(userId);
                await this.setUser(user);
            }
        } catch (err) {
            console.log('error getting user', err);
            let userId = uuidv4();
            user = this.generateNewUser(userId);
            await this.setUser(user);
        }
        return user;
    }
    async setUser(user) {
        await localForage.setItem('sparks_user', user);
    }

    generateNewUser(userId) {
        return {
            userId,
            lastFind: 0,
            lastDrop: 0,
            lastBroadcast: 0,
            lastSend: 0,
            level: 0,
            score: 0,
            updated: Date.now()
        }
    }

    async searchForSparks(location) {

        // Test code :)
        // try {
        //     const data = JSON.parse("{\"payload\":{\"dig\":{\"userId\":\"551ea30e-0a06-40f7-b5a9-fe247e33f82e\",\"radius\":4.358183333333334,\"digId\":\"0eb5eb01-48f8-47d2-8fae-8840a4abe72a\",\"discovers\":15,\"finds\":1,\"messages\":[{\"message\":\"I’m thankful for the little things 😌\",\"date\":1589070237370,\"foundDate\":1589070237531}],\"lat\":33.4719307,\"lng\":-117.6082659,\"discoverRange\":2179,\"broadcast\":{\"message\":\"You are loved!!!\",\"date\":1588904750280,\"foundDate\":1589070237053}},\"user\":{\"lastFind\":1589070236970,\"lastBroadcast\":0,\"updated\":1589070236970,\"level\":0,\"userId\":\"551ea30e-0a06-40f7-b5a9-fe247e33f82e\",\"lastDrop\":1587875258206,\"lastSend\":0,\"score\":50}}}");
            
        //     data.payload.dig.broadcast.foundDate = Date.now();
        //     data.payload.dig.messages[0].foundDate = Date.now();
        //     return {
        //         data
        //       };
        // } catch (err) {
        //     debugger;
        //     throw new Error(err);
        // }
       
        const payload = {
            userId: this.user.userId,
            location
        };
        const method = 'search';
        try {
            const response = await axios.post(this.apiEndpoint, {
                method,
                payload
            });
            return response
        } catch (err) {
            console.log('Error searching spark!', err);
            throw err;
        }
    }

    async dropSpark(message, location) {
        const payload = {
            userId: this.user.userId,
            message,
            location
        };
        const method = 'drop';
        try {
            await axios.post(this.apiEndpoint, {
                method,
                payload
            });
        } catch (err) {
            console.log('Error droppping spark!', err);
            throw err;
        }
    }

    async broadcastSpark(message) {
        const payload = {
            userId: this.user.userId,
            message
        };
        const method = 'broadcast';
        try {
            await axios.post(this.apiEndpoint, {
                method,
                payload
            });
        } catch (err) {
            console.log('Error droppping spark!', err);
            throw err;
        }
    }

    // copied from lambda
    getCurrentSearchRadius(user) {
        const now = Date.now();
        const minutesDiff = this.convertMsToMinutes(now - user.lastFind);
        console.log('minutes since last search', minutesDiff);
        const searchIncrease = this.constants.searchRadiusIncreaseInterval * this.constants.searchRadiusIncreaseAmount * minutesDiff;
        return Math.floor(this.clamp(searchIncrease, this.constants.baseSearchRadiusFeet, this.constants.maxSearchRadiusFeet));
    }

    convertMsToMinutes(ms) {
        return ms / (1000 * 60);
    }

    clamp(number, minVal, maxVal) {
        let res = Math.max(number, minVal);
        res = Math.min(res, maxVal);
        return res;
    }
}