import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Header } from 'semantic-ui-react';

export const ActionsModal = ({
  open,
  header,
  content,
  status,
  sparkFace,
  accept,
  onAccept,
  cancel,
  onCancel,
  onClose,
}) => {
  return <Modal
    open={open}
    size='fullscreen'
    onClose={onClose}>
    <Header>
      {header}
    </Header>
    <Modal.Content>
      <Modal.Description>
        <p>
          {content}
        </p>
      </Modal.Description>
      {/* Face: {sparkFace} */}
    </Modal.Content>
    <Modal.Actions>
      {
        !!cancel &&
        <Button
          onClick={onCancel}
          inverted
          color="red"
        >{cancel}</Button>
      }
      {
        !!accept &&
        <Button
          onClick={onAccept}
          color="green"
        >{accept}</Button>
      }
    </Modal.Actions>
  </Modal>
}

ActionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  status: PropTypes.oneOf([
    'success',
    'error',
  ]).isRequired,
  sparkFace: PropTypes.oneOf([
    'happy',
    'ashamed',
    'sad',
    'sorry',
    'thinking',
    'careful'
  ]).isRequired,
  accept: PropTypes.string,
  onAccept: PropTypes.func,
  cancel: PropTypes.string,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
}
