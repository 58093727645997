import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { inkButton, inkSection, shouldDisable, minSparkLength, maxSparkLength } from './helpers';
import { Accordion, Button, Form, Segment, TextArea } from 'semantic-ui-react';
import { SparkSizeAlert } from './SparkSizeAlert';
import { palette } from '../../styles/palette';

export const DropSpark = ({
  active,
  loading,
  cooldown,
  onAccordionClick,
  onDropSpark,
}) => {

  const [dropSparkMessage, setDropSparkMessage] = useState('');

  const clearDropSparkMessage = () =>
    setDropSparkMessage('');

  const handleFormSubmit = () =>
    onDropSpark(dropSparkMessage, clearDropSparkMessage)

  return [
    <Accordion.Title
        index={1}
        active={active}
        onClick={onAccordionClick}
        key={0}
        className={inkSection(palette.pink[25])}>
        Drop a Spark
    </Accordion.Title>,
    <Accordion.Content
        active={active}
        key={1}
        className={inkSection(palette.pink[25])}>
        <Form onSubmit={handleFormSubmit}>
            <Form.Field>
                <TextArea
                  placeholder='Write your spark'
                  name="dropSparkMessage"
                  value={dropSparkMessage}
                  onChange={(e) => setDropSparkMessage(e.target.value)}
                  disabled={loading}
                  style={{backgroundColor: palette.pink[10]}}/>
            </Form.Field>
            <SparkSizeAlert sparkMessage={dropSparkMessage}/>
            <Button
              fluid
              type="submit"
              loading={loading}
              disabled={loading || shouldDisable(dropSparkMessage) || cooldown > 0}
              size="big"
              className={inkButton(palette.pink[75])}>
                {cooldown <= 0 ? 'Drop Spark' : `Ready in ${cooldown} seconds`}
              </Button>
        </Form>
    </Accordion.Content>
  ];
}

DropSpark.propTypes = {
  active: PropTypes.bool,
  loading: PropTypes.bool,
  cooldown: PropTypes.number,
  onAccordionClick: PropTypes.func,
  onDropSpark: PropTypes.func,
}
