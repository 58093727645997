import React, { PureComponent } from 'react';
import { Container, Header } from 'semantic-ui-react';

export class Privacy extends PureComponent {
    componentDidMount() {
  
    }
  
  
    render() {
      return (
            <Container style={{padding: "1.5rem"}}>
                <Header as='h1'>Privacy</Header>
                <p>Your privacy is important and so is your data. This app stores no data that could be used to identify any of the users.</p>
                <p>All Sparks are reviewed by humans 👩‍💻before being available.</p>
            </Container>
      )
    }
  }