import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import { Button, Divider } from 'semantic-ui-react';
import { About } from './about';
import { Play } from './play/Play';
import { Privacy } from './privacy';
import { WhatsNew } from './whatsnew';

class App extends Component {
  componentDidMount() {

  }


  render() {
    return (
      <Router >
      <div style={{height: "100%"}}>
        <nav>
          <Button.Group className="ui fluid four">
            <Button as={Link} to="/" color="pink" className="corners">📖About</Button>
            <Button as={Link} to="/play" color="teal">✨Play✨</Button>
            <Button as={Link} to="/privacy" color="brown" className="corners">🔐Privacy</Button>
          </Button.Group>
        </nav>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch >
          <Route path="/play">
            <Play/>
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/whatsnew">
            <WhatsNew />
          </Route>
          <Route path="/">
            <About />
          </Route>
        </Switch>
      </div>
    </Router>
    )
  }
}

export default App;
