import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { shouldDisable, maxSparkLength } from './helpers';

export const SparkSizeAlert = ({ sparkMessage }) => {
  return shouldDisable(sparkMessage)  &&
    <Segment inverted color='teal'>
      Write a spark up to <strong>{maxSparkLength}</strong> characters long
    </Segment> ;
}

SparkSizeAlert.propTypes = {
  sparkMessage: PropTypes.string,
}
