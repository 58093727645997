export const palette = {
  blue: {
    10: '#D9F6FE',
    25: '#75D9F6',
    50: '#36C5EE',
    75: '#06ACDB',
    90: '#035A72',
  },
  pink: {
    10: '#FED9EA',
    25: '#FB74B1',
    50: '#F8328C',
    75: '#F0006D',
    90: '#9A0046'
  },
  green: {
    10: '#EFFFD9',
    25: '#C5FD74',
    50: '#A9FB32',
    75: '#92F700',
    90: '#64A800',
  },
  orange: {
    10: '#FFECD9',
    25: '#FFBB75',
    50: '#FF9A33',
    75: '#FF8100',
    90: '#B75D00',
  },
}
