import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';

export const ErrorBanner = ({
  errorMessage
}) =>
  errorMessage.length <= 0 ? null :
      <Segment inverted color='red'>
          {errorMessage}
      </Segment>;

ErrorBanner.propTypes = {
  errorMessage: PropTypes.string
}
