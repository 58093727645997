import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { inkButton, inkSection, shouldDisable } from './helpers';
import { Accordion, Button, Form, Segment, TextArea } from 'semantic-ui-react';
import {SparkSizeAlert} from './SparkSizeAlert';
import { palette } from '../../styles/palette';
import { css } from 'emotion';

export const BroadcastSpark = ({
  active,
  loading,
  cooldown,
  onAccordionClick,
  onBroadcastSpark,
}) => {

  const [broadcastSparkMessage, setBroadcastSparkMessage] = useState('');

  const clearBroadcastSparkMessage = () =>
    setBroadcastSparkMessage('');

  const handleFormSubmit = () =>
    onBroadcastSpark(broadcastSparkMessage, clearBroadcastSparkMessage);

  return [
    <Accordion.Title
        index={2}
        active={active}
        onClick={onAccordionClick}
        key={0}
        className={inkSection(palette.blue[25])}
    >
        Broadcast a Spark
    </Accordion.Title>,
    <Accordion.Content
        active={active}
        key={1}
        className={inkSection(palette.blue[25])}>
          <p style={{textAlign: "left"}}>
                Send out these good vibes 🍀 A broadcast can be found by anyone and anywhere! 
            </p>
        <Form onSubmit={handleFormSubmit}>
            <Form.Field>
                <TextArea
                  placeholder='Write your spark'
                  name="broadcastSparkMessage"
                  value={broadcastSparkMessage}
                  onChange={(e) => setBroadcastSparkMessage(e.target.value)}
                  disabled={loading}
                  style={{backgroundColor: palette.blue[10]}} />
            </Form.Field>
            <SparkSizeAlert sparkMessage={broadcastSparkMessage}/>
            <Button
              fluid
              type="submit"
              loading={loading}
              disabled={loading || shouldDisable(broadcastSparkMessage) || cooldown > 0}
              size="big"
              className={inkButton(palette.blue[75])}>
                {cooldown <= 0 ? 'Broadcast Spark' : `Ready in ${cooldown} seconds`}
              </Button>
        </Form>
    </Accordion.Content>
  ];
}

BroadcastSpark.propTypes = {
  active: PropTypes.bool,
  loading: PropTypes.bool,
  cooldown: PropTypes.number,
  onAccordionClick: PropTypes.func,
  onBroadcastSpark: PropTypes.func,
}
