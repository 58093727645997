import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';

export const InfoMessage = ({
  message
}) =>
  message.length <= 0 ? null :
      <Segment inverted color='teal' className="slide-in-bottom">
          {message}
      </Segment>;

InfoMessage.propTypes = {
  message: PropTypes.string
}
