import React, { PureComponent } from 'react';
import { Accordion } from 'semantic-ui-react';
import { GameService } from '../services/gameService';
import { inkSection } from './partials/helpers';
import { palette } from '../styles/palette';
import {
  ActionsModal,
  BroadcastSpark,
  DropSpark,
  ErrorBanner,
  InfoMessage,
  FindSparks,
  GetLocation,
  Sparks
} from './partials';
import { TOTAL_COOLDOWN } from '../common/constants';

export class Play extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      showLocationMessage: false,
      activeAccordianIndex: 0,
      searchRadius: 4,
      maxSearchRadius: 40,
      foundSparks: [],
      errorMessage: '',
      infoMessage: '',
      loadingFindSparks: false,
      loadingDropSpark: false,
      loadingBroadcastSpark: false,
      modalOpen: false,
      user: null,
      findCooldown: 0,
      dropCooldown: 0,
      broadcastCooldown:0,
    }

    this.totalCooldown = 30;

    this.findTimer = null;
    this.dropTimer = null;
    this.broadcastTimer = null;

    this.modalOptions = {};
    this.location = {}

    this.onTryGetLocation = this.onTryGetLocation.bind(this);
    this.handleAccordionClick = this.handleAccordionClick.bind(this);
    this.handleFindSparks = this.handleFindSparks.bind(this);
    this.handleDropSparkSubmit = this.handleDropSparkSubmit.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleBroadcastSparkSubmit = this.handleBroadcastSparkSubmit.bind(this);

  }

  componentDidMount() {
    this.checkForLocationPermissions();
    this.gameService = GameService.getInstance();
    this.user = this.gameService.getUser()
      .then((user) => {
        this.setState({
          searchRadius: this.gameService.getCurrentSearchRadius(user),
          maxSearchRadius: this.gameService.constants.maxSearchRadiusFeet,
          user: user,
          foundSparks: user.foundSparks || []
        });
      });
    this.updateVisualsInterval = setInterval(() => {
      this.updateVisuals();
    }, 30000);
  }

  updateVisuals() {
    const { user } = this.state;
    this.setState({
      searchRadius: this.gameService.getCurrentSearchRadius(user),
      maxSearchRadius: this.gameService.constants.maxSearchRadiusFeet
    });
  }

  // TODO: Add logic to calculate the new search radius at some decent interval. It increases every minute.
  // Maybe a pull down to refresh or just a hook to the screen coming into view

  checkForLocationPermissions() {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' })
        .then((status) => {
          console.log(status);
          if (status.state !== 'granted') {
            this.setState({
              showLocationMessage: true
            });
          } else {
            this.setState({
              showLocationMessage: false
            });
            this.startLocationWatch();
          }
        });
    } else if (navigator.geolocation) {
      this.startLocationWatch();
    }
  }

  startLocationWatch() {
    let opts = {
      enableHighAccuracy: true,
      timeout: 3000,
      maximumAge: 20000
    };
    navigator.geolocation.watchPosition((locationResponse) => {
      this.location = this.convertgeolocationResponseToLatLng(locationResponse);
    });
  }

  convertgeolocationResponseToLatLng(locationResponse) {
    return { lat: locationResponse.coords.latitude, lng: locationResponse.coords.longitude };
  }

  async handleDropSparkSubmit(message, clearMessage) {
    this.setState({
      loadingDropSpark: true
    });
    const { location } = this;
    this.gameService.dropSpark(message, location)
      .then((result) => {
        clearMessage();
        this.setState({
          loadingDropSpark: false,
        }, () => {
          this.startCooldownTimer(this.dropTimer, 'dropCooldown');
          this.showModal({
            header: 'You have dropped a spark!',
            content: `Thank you for dropping a spark and making someone's day!`,
            status: 'success',
            sparkFace: 'happy',
            accept: 'YAY!',
            onAccept: this.handleModalClose,
          });
        });
      }, (err) => {
        this.setState({
          loadingDropSpark: false,
        });
        this.showModal({
          header: 'Could not drop your spark',
          content: `Something went wrong, but don't worry, our awesome team is on it!`,
          status: 'error',
          sparkFace: 'ashamed',
          accept: 'OK',
          onAccept: this.handleModalClose,
        });
      });

  }



  async handleBroadcastSparkSubmit(message, clearMessage) {
    this.setState({
      loadingBroadcastSpark: true
    });
    const location = { lat: null, lng: null };
    this.gameService.dropSpark(message, location)
      .then((result) => {
        clearMessage();
        this.setState({
          loadingBroadcastSpark: false
        }, () => {
          this.startCooldownTimer(this.broadcastTimer, 'broadcastCooldown');
          this.showModal({
            header: 'You have broadcasted a spark!',
            content: this.getRandomDropSparkMessage(),
            status: 'success',
            sparkFace: 'happy',
            accept: 'YAY!',
            onAccept: this.handleModalClose,
          });
        });
      }, (err) => {
        this.setState({
          loadingBroadcastSpark: false,
        });
        this.showModal({
          header: 'Could not broadcast your spark',
          content: `Something went wront, but don't worry, our awesome team is on it!`,
          status: 'error',
          sparkFace: 'ashamed',
          accept: 'OK',
          onAccept: this.handleModalClose,
        });
      });

  }

  handleModalClose = () => this.setState({ modalOpen: false });

  showModal(modalOptions) {
    this.modalOptions = modalOptions;
    this.modalOptions.onClose = this.handleModalClose;
    this.setState({
      modalOpen: true
    });
  }

  async handleFindSparks() {
    this.setState({
      loadingFindSparks: true,
      infoMessage: ''
    });
    const { location } = this;
    this.gameService.searchForSparks(location)
      .then((result) => {
        this.setState({
          loadingFindSparks: false,
        }, () => {
          this.processFindResults(result.data)

          
          // if (result.data.payload.dig.messages.length) {
          //   this.showModal({
          //     header: 'You got sparks!',
          //     content: `You found ${result.data.payload.dig.messages.length} sparks,
          //                   you can see them in your Found Sparks`,
          //     status: 'success',
          //     sparkFace: 'happy',
          //     accept: 'YAY!',
          //     onAccept: this.handleModalClose,
          //   });
          // } else {
          //   this.showModal({
          //     header: 'No sparks found',
          //     content: `You were not able to find any sparks, better luck next time!`,
          //     status: 'success',
          //     sparkFace: 'sad',
          //     accept: 'OK',
          //     onAccept: this.handleModalClose,
          //   });
          // }
        });

      }, (err) => {
        this.setState({
          loadingFindSparks: false,
        }, () => {
          this.showModal({
            header: 'Could not search for sparks',
            content: `Something went wront, but don't worry, our awesome team is on it!`,
            status: 'error',
            sparkFace: 'ashamed',
            accept: 'OK',
            onAccept: this.handleModalClose,
          });
        });
      });
  }

  getRandomDropSparkMessage() {
    const messages = [
      'Thanks for adding some sparkl to someone\'s day',
      'You brightened up the day',
      'A little light to shine the way'
    ];
    return messages[Math.floor(Math.random() * messages.length)];
  }

  processFindResults(data) {
    if (data && data.payload) {
      // let foo = {
      //   "dig":
      //     { "userId": "325978bb-b8d6-4f0d-9bfb-fdb742150a51", "radius": 25, "digId": "22a46061-07bc-40bb-98d2-4de4693918a1", "discovers": 23, "finds": 0, "messages": [], "lat": 33.4719307, "lng": -117.6082659 },
      //   "user":
      //     { "userId": "325978bb-b8d6-4f0d-9bfb-fdb742150a51", "lastFind": 1588045824318, "lastDrop": 0, "lastBroadcast": 0, "lastSend": 0, "level": 0, "score": 0, "updated": 1588045824318 }
      // }
      const findResult = data.payload.dig;
      const updatedUser = data.payload.user;
      const updatedSearchRadius = this.gameService.getCurrentSearchRadius(updatedUser);
      const { foundSparks } = this.state;
      const discoverRangeMiles = Math.floor(findResult.discoverRange / 528) / 10; // one decimal place
      let findMessage = `You discovered ${findResult.discovers} Sparks within about ${discoverRangeMiles} miles and found ${findResult.finds}.`
      if (findResult.broadcast) {
        findMessage += `\n
        You received a broadcast! 📡`;
        findResult.messages.push({...findResult.broadcast, isBroadcast: true});
      }
      const sortedFoundSparks = this.updateFoundSparksArray(foundSparks, findResult.messages);
      this.setState({
        foundSparks: sortedFoundSparks,
        searchRadius: updatedSearchRadius,
        infoMessage: findMessage // TODO: don't use error block of course. Hide message after 10 seconds.
      }, () => this.startCooldownTimer(this.findTimer, 'findCooldown'));
      
      updatedUser.foundSparks = sortedFoundSparks;
      this.gameService.setUser(updatedUser);
    }
  }

  updateFoundSparksArray(currentValues, newValues) {
      return currentValues.concat(newValues).sort((a, b) => a.foundDate < b.foundDate ? 1 : -1).slice(0, 15);
  }

  handleAccordionClick(e, titleProps) {
    const { index } = titleProps
    const { activeAccordianIndex } = this.state
    const newIndex = activeAccordianIndex === index ? -1 : index

    this.setState({ activeAccordianIndex: newIndex })
  }

  onTryGetLocation = () => {
    this.startLocationWatch();
    setTimeout(() => {
      this.checkForLocationPermissions();
    }, 2000);
  }

  startCooldownTimer(targetTimer, targetSecs){
    this.setState({
      [targetSecs]: TOTAL_COOLDOWN
    });

    targetTimer = setInterval(() => {
      const nextSecs = this.state[targetSecs] - 1;
      this.setState({
        [targetSecs]: nextSecs
      }, () => {
        if (nextSecs <= 0) {
          this.clearCooldowntimer(targetTimer);
        }
      });
    }, 1000);

  }

  clearCooldowntimer(targetTimer){
    clearInterval(targetTimer);
    targetTimer = null;
  }

  render() {
    const {
      showLocationMessage,
      activeAccordianIndex,
      searchRadius,
      maxSearchRadius,
      foundSparks,
      errorMessage,
      infoMessage,
      loadingFindSparks,
      loadingDropSpark,
      loadingBroadcastSpark,
      modalOpen,
      findCooldown,
      dropCooldown,
      broadcastCooldown,
    } = this.state;
    return (
      <>
        {
          showLocationMessage === true ?
            (
              <GetLocation onTryGetLocation={this.onTryGetLocation} />
            )
            :
            <Accordion fluid styled>
              <FindSparks
                active={activeAccordianIndex === 0}
                loading={loadingFindSparks}
                cooldown={findCooldown}
                searchRadius={searchRadius}
                maxSearchRadius={maxSearchRadius}
                onAccordionClick={this.handleAccordionClick}
                onFindSparks={this.handleFindSparks}
              />
              <DropSpark
                active={activeAccordianIndex === 1}
                loading={loadingDropSpark}
                cooldown={dropCooldown}
                onAccordionClick={this.handleAccordionClick}
                onDropSpark={this.handleDropSparkSubmit}
              />
              <BroadcastSpark
                active={activeAccordianIndex === 2}
                loading={loadingBroadcastSpark}
                cooldown={broadcastCooldown}
                onAccordionClick={this.handleAccordionClick}
                onBroadcastSpark={this.handleBroadcastSparkSubmit}
              />
            </Accordion>
        }
        <div
          fluid
          style={{ padding: "1.5rem 0.5rem 0.5rem 0.5rem", minHeight: "100%" }}
          className={inkSection(palette.green[10])}>
          {
            errorMessage ? <ErrorBanner errorMessage={errorMessage} /> : null
          }
          {
            infoMessage ? <InfoMessage message={infoMessage} /> : null
          }
          <Sparks foundSparks={foundSparks} />
          <ActionsModal open={modalOpen} {...this.modalOptions} />
        </div>
      </>
    );
  }
}
